// Generated by Framer (c163575)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-5StoA"

const variantClassNames = {Lu5Vcrca5: "framer-v-1atr9c5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Lu5Vcrca5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 400, pixelWidth: 400, src: "https://framerusercontent.com/images/qDLB0tmzoFLcPNv9V6GnCQbSCZg.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1atr9c5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Lu5Vcrca5"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5StoA.framer-26gi99, .framer-5StoA .framer-26gi99 { display: block; }", ".framer-5StoA.framer-1atr9c5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; min-height: 44px; min-width: 36px; overflow: visible; padding: 10px 0px 10px 10px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5StoA.framer-1atr9c5 { gap: 0px; } .framer-5StoA.framer-1atr9c5 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-5StoA.framer-1atr9c5 > :first-child { margin-left: 0px; } .framer-5StoA.framer-1atr9c5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerP7V4CNIbq: React.ComponentType<Props> = withCSS(Component, css, "framer-5StoA") as typeof Component;
export default FramerP7V4CNIbq;

FramerP7V4CNIbq.displayName = "Logo";

FramerP7V4CNIbq.defaultProps = {height: 44, width: 36};

addFonts(FramerP7V4CNIbq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})